import { Component, OnInit } from '@angular/core';
import { Storage } from  '@ionic/storage';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Events} from './services/event.service';
import { MEMELORD_ROLE } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public countPosts = 0;
  public isLoggedIn:boolean;
  public role:any;
  public appPages = [
    {
      title: 'Home',
      url: '',
      icon: 'home',
      status :'all'
    },
    {
      title: 'Random',
      url: '/random',
      icon: 'repeat',
      status :'all'
    },
    {
      title: 'Rules',
      url: '/rules',
      icon: 'receipt',
      status :'all'
    },
    {
      title: 'Statistics',
      url: '/statistics',
      icon: 'stats-chart',
      status :'all'
    },
    {
      title: 'About 420GAG',
      url: '/about',
      icon: 'briefcase',
      status :'all'
    },
    {
      title: 'Login/Register',
      url: '/auth',
      icon: 'person-circle',
      status :'loggedout'
    },
    {
      title: 'Upload',
      url: '/upload',
      icon: 'cloud-upload',
      status :'role:memelord'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private events:Events,
    private storage:Storage
  ) {
    this.initializeApp();
    this.initializeEvents();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  initializeEvents() {
    this.events.subscribe('user:login', (data: any) => {
      this.isLoggedIn = true;
      this.role = data.role;
    });
  }


  

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  show(status){
    if((status=='loggedout' && !this.isLoggedIn )){
      return true;
    }else if(status=='all'){
      return true;
    }else if(status=="role:memelord"){
      
      if(this.role == MEMELORD_ROLE){
        return true;
      }
    }
    return (status=='loggedout' && !this.isLoggedIn ) || (status=='all')
  }
}
