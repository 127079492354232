import { Component, OnInit } from '@angular/core';
import { GagService } from './../services/gag.service';
import { FileService } from './../services/file.service';
import { Gag,GagRequest } from './../class/gag';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public gags:any[];
  public isRunning:boolean;
  public page;
  public searchTerm;
  public isAllLoaded:boolean;
  constructor(private gagService:GagService, private fileService:FileService) { }

  ngOnInit() {
    this.page = 1;
    this.gags = new Array();
    this.searchTerm = "";
    this.isRunning=false;
    this.isAllLoaded = false;
    this.loadGags(null,"");
  }

  loadGags(event,searchTerm){
    
    if(this.isRunning){
      return;
    }
    this.isRunning =true;
    this.gagService.getGags(this.searchTerm,this.page).subscribe((gags:GagRequest)=>{
      gags.data.forEach((gag:Gag) => {
        this.gags.push(gag);
        this.loadFileForGag(gag);
      });
      if(event!=null){
        event.target.complete();
      }
      if(gags.data.length < this.gagService.per_page){
        this.isAllLoaded = true;
      }
      this.isRunning = false;
    },
      error=>{
        if(error.status == 400){

          this.isAllLoaded = true;
          event.target.complete();
        }
      }
      
  );
    this.page= this.page+1;
  }

  loadFileForGag(gag:Gag){
    this.fileService.getFile(gag.file).subscribe((data:any)=>{
     gag.fileUrl = data.data.data.full_url;
    })
  }
  
  setSearch(event){
    const searchTerm = event.srcElement.value;

    if (!searchTerm || searchTerm.length < 3) {
      return;
    }else{
      this.searchTerm = searchTerm;
      this.gags = new Array();
      this.page = 1;
      this.loadGags(null,searchTerm);
    }
  }

  upvoteGag(){

  }
  downvoteGag(){
    
  }
}
