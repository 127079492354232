import { Injectable } from '@angular/core';
import { BASE_URL } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FileService {
  url = BASE_URL + '/files';
  constructor(private httpClient: HttpClient) { }

  getFile(id) {
    return this.httpClient.get(this.url + "/" + id, {});
  }
}
