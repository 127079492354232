import { Injectable } from '@angular/core';
import { BASE_URL } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GagService {
  url = BASE_URL + '/items/gag';
  public per_page = 10;

  constructor(private httpClient: HttpClient) { }
  getGags(search, page) {
    return this.httpClient.get(this.url + "?meta=total_count&sort=-created_on&limit=10&page=" + page + "&filter[title][like]=" + search, {});
  }

  getRandomGags() {
    return this.httpClient.get(this.url + "?sort=?", {});
  }

  getMeta() {
    return this.httpClient.get(this.url + "?meta=*&filter[file][eq]=0", {});
  }


  createGag(title, image_path,token) {
    let authHeaders = this.getAuthorizationHeaderBrowser(token);
    let postData = {
      "title": title,
      "file": image_path,
      "status": "published"
    }
    return this.httpClient.post(this.url, postData,  {headers: authHeaders});
  }
  getAuthorizationHeaderBrowser(token: string): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
   
  }
}
